import * as React from "react";
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdbreact";

import CardNews from "../components/CardNews/CardNews";
import Layout from "../Layouts/MasterLayout/MasterLayout";
import CarouselCampanha2022 from "../components/CarouselCampanha2022/CarouselCampanha2022";

export default function ConhecaAFeevaleWayPage() {
	return <></>;
	// return (
	// 	<Layout>
	// 		<MDBContainer>
	// 			<MDBRow>
	// 				<MDBCol>
	// 					<h2 className="h1-responsive font-weight-bold text-center my-5 text-secondary text-uppercase">
	// 						Conheça a Feevale Way
	// 					</h2>
	// 				</MDBCol>
	// 			</MDBRow>
	// 			<MDBRow>
	// 				<MDBCol>
	// 					<p className="text-primary">
	// 						Feevale Way é a nossa maneira de aprender fazendo. Currículos
	// 						atualizados em uma mistura de atividades práticas, autonomia,
	// 						tecnologia, ensino digital e mão na massa, proporcionando uma
	// 						experiência que vai muito além da sala de aula.
	// 					</p>
	// 				</MDBCol>
	// 			</MDBRow>
	// 			<MDBRow>
	// 				<MDBCol>
	// 					<CarouselCampanha2022 />
	// 				</MDBCol>
	// 			</MDBRow>
	// 			<MDBRow className="mt-4">
	// 				<MDBCol>
	// 					<p className="text-primary">
	// 						Veja um pouco do que os seus futuros colegas já estão fazendo:
	// 					</p>
	// 				</MDBCol>
	// 			</MDBRow>
	// 			<MDBRow>
	// 				<MDBCol>
	// 					<CardNews />
	// 				</MDBCol>
	// 			</MDBRow>
	// 			<MDBRow>
	// 				<MDBCol>
	// 					<MDBBtn
	// 						block
	// 						color="secondary"
	// 						className="text-primary font-weight-bold mt-3"
	// 						target="_blank"
	// 						rel="noreferrer"
	// 						href="https://www.feevale.br/acontece/noticias"
	// 					>
	// 						Veja mais
	// 					</MDBBtn>
	// 				</MDBCol>
	// 			</MDBRow>
	// 		</MDBContainer>
	// 	</Layout>
	// );
}
